import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

const SecurePage = ({ location }) => {
  return (
    <div className="global-wrapper">
      <header className="header global-header">
        <h1>
          <FormattedMessage
            id="secure_page"
            defaultMessage="This Page is Secure"
          />
        </h1>
      </header>
      <main></main>
    </div>
  );
};

export default SecurePage;
